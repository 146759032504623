var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain"},[(
      _vm.info.orderStatus === '配送中' ||
      _vm.info.orderStatus === '待配送' ||
      _vm.info.orderStatus === '已抵达'
    )?_c('el-amap',{attrs:{"id":"amap","zoom":_vm.zoom,"center":_vm.center}},_vm._l((_vm.markers),function(item){return _c('el-amap-marker',{key:item.id,attrs:{"position":item.position}})}),1):_vm._e(),(
      _vm.info.orderStatus === '配送中' ||
      _vm.info.orderStatus === '待配送' ||
      _vm.info.orderStatus === '已抵达'
    )?_c('div',{staticClass:"mapTip"},[_vm._v(" 由新石器无人车为您配送 ")]):_vm._e(),_c('div',{staticClass:"main",style:(_vm.info.orderStatus === '配送中' || _vm.info.orderStatus === '待配送'
        ? 'height:auto; padding:0 .30rem .30rem;'
        : '')},[_c('div',[(_vm.info.orderStatus === '待支付')?_c('div',{staticClass:"orderState"},[_c('div',{staticClass:"stateName payWaiting"},[_vm._v("订单"+_vm._s(_vm.info.orderStatus))]),_c('div',{staticClass:"time"},[_vm._v(" 未支付订单 "),_c('span',{staticStyle:{"color":"#ff6e40"}},[_vm._v(_vm._s(_vm.leftTime))]),_vm._v(" 秒后自动关闭 ")])]):_vm._e(),(_vm.info.orderStatus === '已取消')?_c('div',{staticClass:"orderState"},[_c('div',{staticClass:"stateName cancel"},[_vm._v("订单"+_vm._s(_vm.info.orderStatus))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.info.cancelDate))])]):_vm._e(),(_vm.info.orderStatus === '已完成')?_c('div',{staticClass:"orderState"},[_c('div',{staticClass:"stateName"},[_vm._v("订单"+_vm._s(_vm.info.orderStatus))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.info.completeDate))])]):_vm._e(),(
          _vm.info.orderStatus === '待配送' ||
          _vm.info.orderStatus === '配送中' ||
          _vm.info.orderStatus === '未取货'
        )?_c('div',{staticClass:"orderState"},[_c('div',{staticClass:"stateName"},[_vm._v(" 已收到您的订单 "),_c('span',[_vm._v("预计 "+_vm._s(_vm.info.toTime)+" 送达")])]),_vm._m(0)]):_vm._e(),(_vm.info.refundStatus !== 0)?_c('div',{staticClass:"refund"},[(_vm.info.refundStatus === 1)?_c('div',[_c('div',[_vm._v("退款进度：退款处理中")]),_c('div',{staticClass:"desc"},[_vm._v("预计1～3个工作日到账，请耐心等待。")])]):_vm._e(),(_vm.info.refundStatus === 2)?_c('div',[_vm._v("退款进度：已退款")]):_vm._e(),(_vm.info.refundStatus === 3)?_c('div',[_c('div',[_vm._v("退款进度：退款失败")]),_c('div',{staticClass:"callPhone",on:{"tap":function($event){return _vm.callPhone()}}},[_vm._v("联系客服")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"details"},[(
            _vm.info.orderStatus === '待配送' ||
            _vm.info.orderStatus === '配送中' ||
            _vm.info.orderStatus === '未取货' ||
            _vm.info.orderStatus === '已抵达'
          )?_c('div',{staticClass:"codeBox"},[_c('div',{staticClass:"code"},[_vm._v(_vm._s(_vm.info.pickUpCode || "取货码生成中.."))]),_c('div',[_vm._v("取货码")])]):_vm._e(),_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"address"},[_vm._v(_vm._s(_vm.info.stationName))]),_c('div',{staticClass:"p2"},[_vm._v(_vm._s(_vm.info.userName)+_vm._s(_vm.info.userPhone))])]),_vm._l((_vm.info.orderSkuList),function(item){return _c('div',{key:item.skuCode,staticClass:"skuBox"},[_c('div',{staticClass:"cont"},[_c('img',{attrs:{"src":item.skuImage}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.skuName)+" "),_c('span',{staticClass:"num"},[_vm._v("x"+_vm._s(item.skuCount))])]),(item.supllierName)?_c('div',{staticClass:"src"},[_c('img',{staticClass:"img",attrs:{"src":item.supllierLogo}}),_c('span',[_vm._v(_vm._s(item.supllierName))])]):_vm._e(),_c('div',{staticClass:"price"},[_vm._v("¥"+_vm._s(item.skuPrice))])])])])}),_c('div',{staticClass:"orderInfo"},[_c('div',[_c('span',[_vm._v("订单号")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.info.orderCode))])]),_c('div',[_c('span',[_vm._v("下单时间")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.info.createDate))])])])],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time"},[_c('img',{attrs:{"src":require("../../assets/imgs/regionTip.png")}}),_c('span',[_vm._v("无人车抵达配送点后将发送短信+语音电话通知，敬请留意！")])])
}]

export { render, staticRenderFns }