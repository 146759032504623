<template>
  <div class="contain">
    <el-amap
      id="amap"
      v-if="
        info.orderStatus === '配送中' ||
        info.orderStatus === '待配送' ||
        info.orderStatus === '已抵达'
      "
      :zoom="zoom"
      :center="center"
    >
      <el-amap-marker
        v-for="item in markers"
        :position="item.position"
        :key="item.id"
      ></el-amap-marker>
    </el-amap>
    <div
      class="mapTip"
      v-if="
        info.orderStatus === '配送中' ||
        info.orderStatus === '待配送' ||
        info.orderStatus === '已抵达'
      "
    >
      由新石器无人车为您配送
    </div>
    <div
      class="main"
      :style="
        info.orderStatus === '配送中' || info.orderStatus === '待配送'
          ? 'height:auto; padding:0 .30rem .30rem;'
          : ''
      "
    >
      <div>
        <div class="orderState" v-if="info.orderStatus === '待支付'">
          <div class="stateName payWaiting">订单{{ info.orderStatus }}</div>
          <div class="time">
            未支付订单
            <span style="color: #ff6e40">{{ leftTime }}</span>
            秒后自动关闭
          </div>
        </div>
        <div class="orderState" v-if="info.orderStatus === '已取消'">
          <div class="stateName cancel">订单{{ info.orderStatus }}</div>
          <div class="time">{{ info.cancelDate }}</div>
        </div>
        <div class="orderState" v-if="info.orderStatus === '已完成'">
          <div class="stateName">订单{{ info.orderStatus }}</div>
          <div class="time">{{ info.completeDate }}</div>
        </div>
        <div
          class="orderState"
          v-if="
            info.orderStatus === '待配送' ||
            info.orderStatus === '配送中' ||
            info.orderStatus === '未取货'
          "
        >
          <div class="stateName">
            已收到您的订单 <span>预计 {{ info.toTime }} 送达</span>
          </div>
          <div class="time">
            <img src="../../assets/imgs/regionTip.png" />
            <span>无人车抵达配送点后将发送短信+语音电话通知，敬请留意！</span>
          </div>
        </div>

        <div class="refund" v-if="info.refundStatus !== 0">
          <div v-if="info.refundStatus === 1">
            <div>退款进度：退款处理中</div>
            <div class="desc">预计1～3个工作日到账，请耐心等待。</div>
          </div>

          <div v-if="info.refundStatus === 2">退款进度：已退款</div>

          <div v-if="info.refundStatus === 3">
            <div>退款进度：退款失败</div>
            <div class="callPhone" @tap="callPhone()">联系客服</div>
          </div>
        </div>

        <div class="details">
          <div
            class="codeBox"
            v-if="
              info.orderStatus === '待配送' ||
              info.orderStatus === '配送中' ||
              info.orderStatus === '未取货' ||
              info.orderStatus === '已抵达'
            "
          >
            <div class="code">{{ info.pickUpCode || "取货码生成中.." }}</div>
            <div>取货码</div>
          </div>

          <div class="userInfo">
            <div class="address">{{ info.stationName }}</div>
            <div class="p2">{{ info.userName }}{{ info.userPhone }}</div>
          </div>

          <div
            class="skuBox"
            v-for="item in info.orderSkuList"
            :key="item.skuCode"
          >
            <div class="cont">
              <img :src="item.skuImage" />
              <div class="info">
                <div class="name">
                  {{ item.skuName }}
                  <span class="num">x{{ item.skuCount }}</span>
                </div>
                <div class="src" v-if="item.supllierName">
                  <img class="img" :src="item.supllierLogo" />
                  <span>{{ item.supllierName }}</span>
                </div>
                <div class="price">¥{{ item.skuPrice }}</div>
              </div>
            </div>
          </div>

          <div class="orderInfo">
            <div>
              <span>订单号</span>
              <span class="value">{{ info.orderCode }}</span>
            </div>
            <div>
              <span>下单时间</span>
              <span class="value">{{ info.createDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "detailsDelivery",
  components: {},
  data() {
    return {
      zoom: 16,
      center: [116.494958, 39.977889],
      info: "",
      leftTime: "--", //  待支付状态下的倒计时
      markers: [],
    };
  },

  created: function () {
    this.getOrderDetail();
  },

  mounted: function () {
    console.log(this.$route.query);
  },

  methods: {
    getOrderDetail() {
      let data = {
        orderCode: "1000110917",
      };
      request({
        url: "/aquaWxApp/orderDetail",
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {
        console.log(res);
        let data = res.data;
        data.orderStatus = "待配送";
        data.refundStatus = 0;

        this.info = data;
        this.leftTime = data.secLeft * 1 < 0 ? 0 : data.secLeft; //剩余时间
        data.secLeft && data.secLeft > 0 && this.time(data.secLeft); //如果有倒计时则执行倒计时函数
      });
    },
    time(time) {
      if (time) {
        this.leftTime = time;

        this.t = setInterval(() => {
          this.leftTime = --this.leftTime;
          this.$apply();

          if (this.leftTime <= 0) {
            this.getOrderDetail();
            clearInterval(this.t);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  float: left;

  #amap {
    width: 100%;
    height: 7.5rem;
  }

  .orderState {
    width: calc(100% - 0.6rem);
    background: #fff;
    border-radius: 0.08rem;
    padding: 0.3rem;
    font-weight: bold;

    .stateName {
      height: 0.67rem;
      font-size: 0.4rem;
      color: #222;
      font-weight: bold;

      &.payWaiting {
        color: #ff6e40;
      }
      &.cancel {
        color: #a0a0a0;
      }

      span {
        font-size: 0.28rem;
        color: #222;
        font-weight: bold;
        float: right;
      }
    }
    .time {
      font-size: 0.28rem;
      color: #999;
      margin-top: 0.24rem;
      display: flex;

      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.16rem;
      }
      span {
        flex: 1;
        color: #222;
      }
    }
  }
  .details {
    padding: 0 0.3rem;
    background: #fff;
    border-radius: 0.08rem;
    margin-top: 0.2rem;
    font-size: 0.32rem;
    color: #222;

    & > .codeBox {
      padding: 0.3rem 0;
      text-align: center;
      font-size: 0.28rem;
      color: #222;
      border-bottom: 0.01rem solid #e0e0e0;

      .code {
        font-size: 0.68rem;
        color: #ff6e40;
        height: 0.95rem;
        font-weight: bold;
      }
    }
    & > .userInfo {
      padding: 0.3rem 0;
      border-bottom: 0.01rem solid #e0e0e0;

      .address {
        height: 0.45rem;
        font-size: 0.32rem;
        color: #222;
      }
      .p2,
      .p3 {
        font-size: 0.28rem;
        color: #999;
        height: 0.4rem;
        margin-top: 0.1rem;
      }
    }
    .skuBox {
      background: #fff;
      border-radius: 0.08rem;
      width: 100%;
      padding: 0.3rem 0;
      /*margin-top: .20rem;*/
      display: flex;

      & > img {
        width: 1.32rem;
        height: 1.32rem;
        margin-right: 0.3rem;
      }
      & > .info {
        flex: 1;
        display: flex;
        flex-direction: column;

        .name {
          /*width: 4.68rem;*/
          min-height: 0.32rem;
          font-size: 0.28rem;
          color: #000;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .orderInfo {
      padding: 0.3rem 0 0.2rem;
      border-top: 0.01rem solid #e0e0e0;

      & > div {
        font-size: 0.28rem;
        color: #222;
        height: 0.4rem;
        margin-bottom: 0.1rem;

        span:first-child {
          color: #a0a0a0;
        }
        span:last-child {
          float: right;
        }
      }
    }
  }
  page {
    background: #f5f5f5;
    /*height: 100%;*/
    /*animation: all 0.3s;*/
  }
  .main {
    padding: 0.2rem 0.3rem;
    height: 100%;
  }
  .flo {
    position: fixed;
    width: 92%;
    bottom: 0.3rem;
    left: 4%;
    z-index: 2;
    box-sizing: border-box;

    .open {
      width: 0.76rem;
      height: 0.38rem;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -0.38rem;
    }
  }
  .icon-btn {
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    top: 6.5rem;
    right: 0.3rem;
    z-index: 2;
    background: #fff;
    /*box-shadow: 0. 2rem .10rem 0 rgba(0,0,0,0.20);*/
    border-radius: 50%;

    cover-image {
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.2rem;
    }
  }
  .mapTip {
    width: 7.1rem;
    height: 0.76rem;
    line-height: 0.76rem;
    background: #fff;
    box-shadow: 0 0.02rem 0.1rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.08rem;
    padding: 0 0.3rem;
    font-size: 0.28rem;
    color: #222;
    position: fixed;
    top: 0.18rem;
    left: 0.2rem;
    box-sizing: border-box;
  }
  .refund {
    width: calc(100% - 0.6rem);
    background: #fff;
    border-radius: 0.08rem;
    padding: 0.3rem;
    font-size: 0.32rem;
    color: #222;
    margin-top: 0.2rem;
    position: relative;

    .desc {
      font-size: 0.28rem;
      color: #a0a0a0;
      margin-top: 0.1rem;
    }
    .callPhone {
      width: 1.88rem;
      height: 0.62rem;
      line-height: 0.62rem;
      border: 0.01rem solid #ff6e40;
      border-radius: 0.08rem;
      font-size: 0.28rem;
      color: #ff6e40;
      text-align: center;
      position: absolute;
      right: 0.3rem;
      top: 0.22rem;
    }
  }
  .details {
    .userInfo {
      .p2,
      .p3 {
        color: #999;
      }
      .p3 {
        margin-top: 0.04rem;
      }
    }
    .skuBox {
      display: flex;
      flex-direction: column;

      .tit {
        font-size: 0.3rem;
        color: #222;
        margin-bottom: 0.2rem;
        font-weight: bold;
        display: flex;
        text-align: center;
        align-items: center;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: 0.04rem;
        }
      }
      .cont {
        display: flex;

        img {
          width: 1.32rem;
          height: 1.32rem;
          margin-right: 0.3rem;
        }
        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          /*justify-content: space-between;*/
          /*align-items: center;*/
          font-size: 0.28rem;

          .name {
            color: #000;
            max-width: 5.88rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .num {
            font-size: 0.28rem;
            color: #222;
            float: right;
            /*float: right;*/
          }
          .src {
            margin-top: 0.16rem;
            display: flex;
            color: #222;
            font-size: 0.22rem;
            .img {
              width: 0.36rem;
              height: 0.36rem;
              margin-right: 0.08rem;
            }
          }
          .price {
            font-size: 0.36rem;
            color: #222;
            height: 0.5rem;
            text-align: right;
            font-weight: bold;
          }
        }
      }
    }
  }
  .orderInfo {
    span {
      color: #999;
      font-size: 0.28rem;

      &.value {
        color: #222;
      }
    }
  }
}
</style>
